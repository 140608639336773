import { createTheme } from "@material-ui/core/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffeb34",
      // main: "#fcb811",
      // contrastText: "#f2bb43",
      contrastText: "#111",
    },
    secondary: {
      main: "#111111",
    },
    background: {
      default: "#ffeb34",
    },
    text: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: "Quicksand, sans-serif",
    h1: {
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    h2: {
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: "#000000",
        "&$focused": {
          color: "#000",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        background: "inherit",
        fontSize: "1rem",
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomColor: "#212121",
        },
      },
    },
    MuiStepLabel: {
      active: {
        fontWeight: "bold !important",
      },
    },
    MuiFilledInput: {
      root: {
        background: "white !important",
      },
    },
    MuiTabs: {
      indicator: {
        height: 4,
      },
    },
    MuiTab: {
      fullWidth: {
        borderBottom: "4px solid black",
      },
      textColorInherit: {
        opacity: 0.5,
      },
    },
    MuiCard: {
      root: {
        background: "transparent",
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: "white",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        "&$contained": {
          fontWeight: "bold",
        },
      },
    },
    MuiTableCell: {
      footer: {
        border: "none",
      },
    },
  },
})

export default theme
